import React from 'react';

interface Props { 

}

const Placeholder : React.FC<Props> = props => {

  const {} = props;

  return (
    <div>
      This is a placeholder page.
    </div>
  );
}

export default Placeholder; 